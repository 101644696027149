import useApi from '../../hooks/useApi';

const API_URL = '/api/domains';

type Domain = {
  domainLink: string;
  domainName: string;
  active: boolean;
};

function useDomain() {
  const { api: apiClient, createApiCall } = useApi();

  const getDomains = createApiCall(async ({ signal }: any) => {
    const {
      data: { domains },
    } = await apiClient.get(API_URL, { signal });

    return domains;
  });

  const getDomainsIsEntry = createApiCall(async ({ signal }: any) => {
    const {
      data: { domains },
    } = await apiClient.get(`${API_URL}/is-entry`, { signal });

    return domains;
  });

  const addDomain = createApiCall(
    async ({ signal }: any, newDomain: Domain) => {
      const {
        data: { domain },
      } = await apiClient.post(API_URL, newDomain, { signal });

      return domain;
    }
  );

  const editDomain = createApiCall(
    async ({ signal }: any, domainId: string, newDomain: Domain) => {
      const {
        data: { domain },
      } = await apiClient.put(`${API_URL}/${domainId}`, newDomain, { signal });

      return domain;
    }
  );

  const deleteDomain = createApiCall(
    async ({ signal }: any, domainId: string) => {
      await apiClient.delete(`${API_URL}/${domainId}`, { signal });
    }
  );

  return { getDomains, addDomain, editDomain, deleteDomain, getDomainsIsEntry };
}

export default useDomain;
