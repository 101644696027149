import { MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import LoadingButton from '../../../components/LoadingButton';
import SimpleModal from '../../../components/SimpleModal';

type Props = {
  open: boolean;
  handleClose: () => void;
  onSubmit: (newValues: Domain) => Promise<any>;
};

type Domain = {
  domainName: string;
  domainLink: string;
  isEntry: boolean;
};

function AddDomain({ open, handleClose, onSubmit }: Props) {
  const [values, setValues] = useState({ domainName: '', domainLink: '', isEntry: false });
  const [loading, setLoading] = useState(false);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setValues(old => ({ ...old, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit(values);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add domain</Typography>
      <TextField onChange={onChange} name="domainName" label="Domain Name" />
      <TextField onChange={onChange} name="domainLink" label="Domain Link" />
      <TextField
        onChange={onChange}
        name="isEntry"
        label="Is Entry"
        select
        value={values?.isEntry ? 1 : 0}
        InputLabelProps={{ shrink: true }}
      >

        <MenuItem value={0}>No</MenuItem>
        <MenuItem value={1}>Yes</MenuItem>
      </TextField>
      <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">
        Submit
      </LoadingButton>
    </SimpleModal>
  );
}

export default AddDomain;
