import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ImportOffer from '../pages/offer/ImportOffer';
import { Download } from '@mui/icons-material';

export default function DynamicToolbar({
  modalButtons = [],
  allowExport = false,
  allowImport = false,
  exportOptions = {},
  rawExportData = [],
  onImportOffer,
  exportFileName = 'tableData.csv',
  onDownloadTemplate,
}) {
  const [modalStates, setModalStates] = useState([]);
  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    setModalStates(init(modalButtons));
  }, [modalButtons]);

  const handleModalVisibility = (name, isVisible) => {
    const matchModal = modalStates.find((item) => item.name === name);

    matchModal.open = isVisible;

    setModalStates(modalStates.slice());
  };

  const handleOpenModal = (name) => () => {
    handleModalVisibility(name, true);
  };

  const handleCloseModal = (name) => () => handleModalVisibility(name, false);

  const formatExportData = (headerMap, data) => {
    const headerEntries = Object.entries(headerMap);

    const headerLabels = headerEntries.map(([key, options]) => {
      if (typeof options === 'string') return options;
      return options.label;
    });

    const formattedData = data.map((item) => {
      const row = headerEntries.map(([key, options]) => {
        const itemValue = item[key];

        const toTransform = Boolean(options.formatter);

        if (toTransform) return options.formatter(itemValue, item);

        return itemValue;
      });

      return row;
    });

    formattedData.unshift(headerLabels);
    return formattedData;
  };

  const handleExport = (e, done) => {
    const formattedData = formatExportData(exportOptions, rawExportData);
    setExportData(formattedData);
    done(true);
  };

  return (
    <>
      <Box p={1} display="flex" gap={1}>
        {modalStates.map((item) => {
          const toRender = Boolean(item.renderIf);

          if (!toRender) return null;

          return (
            <Button onClick={handleOpenModal(item.name)} startIcon={item.icon}>
              {item.label}
            </Button>
          );
        })}

        {allowImport && (
          <Button startIcon={<Download />} onClick={onDownloadTemplate}>
            Download Template
          </Button>
        )}

        {allowExport && (
          <>
            <CSVLink
              data={exportData}
              filename={exportFileName}
              asyncOnClick
              onClick={handleExport}
            >
              <Button startIcon={<FileDownloadIcon />}>Export</Button>
            </CSVLink>
          </>
        )}

        {allowImport && (
          <>
            <ImportOffer onUpload={onImportOffer} />
          </>
        )}
      </Box>
      {modalStates.map((item) => {
        const handleClose = handleCloseModal(item.name);

        const toRender = Boolean(item.renderIf);

        if (!toRender) return null;

        return item.render(item.open, handleClose);
      })}
    </>
  );
}

const init = (requestedButtons) => {
  return requestedButtons.map((item) => {
    return {
      ...item,
      open: false,
    };
  });
};
