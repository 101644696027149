import React, { useState, useRef } from 'react';
import {
  TextField,
  Typography,
  IconButton,
  MenuItem,
  Button,
} from '@mui/material';
import SimpleModal from '../../../components/SimpleModal';
import LoadingButton from '../../../components/LoadingButton';
import GroupFieldSelector from '../GroupFieldSelector';
import DeleteIcon from '@mui/icons-material/Delete';
import * as nanoid from 'nanoid';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (updatedOffer: any) => any;
  offerData: any;
};

type Condition = {
  id: string;
  type: string;
  value: string;
};

const conditionTypes = [
  { label: 'Minimum Length', value: 'minLength' },
  { label: 'Maximum Length', value: 'maxLength' },
  { label: 'Contains', value: 'contains' },
  { label: 'Starts With', value: 'startsWith' },
  { label: 'Ends With', value: 'endsWith' },
];

const EditCustomOfferGroup = ({
  open,
  handleClose,
  onSubmit,
  offerData,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    label: offerData.label ?? '',
    key: offerData.key ?? '',
  });
  const [conditions, setConditions] = useState<Condition[]>(
    offerData.conditions || []
  );

  const fieldRef = useRef<any>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleAddCondition = () => {
    setConditions((prev) => [
      ...prev,
      { id: nanoid.nanoid(), type: '', value: '' },
    ]);
  };

  const handleConditionChange = (id: string, field: keyof Condition, value: string) => {
    setConditions((prev) =>
      prev.map((cond) => (cond.id === id ? { ...cond, [field]: value } : cond))
    );
  };

  const handleDeleteCondition = (id: string) => {
    setConditions((prev) => prev.filter((cond) => cond.id !== id));
  };

  const handleSubmit = async () => {
    if (!fieldRef.current) return;
    setLoading(true);

    const fields = fieldRef.current.getFields();

    await onSubmit({
      ...values,
      fields,
      conditions,
    });
    setLoading(false);
  };

  const selectedTypes = conditions.map((cond) => cond.type);

  return (
    <SimpleModal
      open={open}
      handleClose={handleClose}
      containerStyle={{ minWidth: 600 }}
    >
      <Typography variant="h6" gutterBottom>
        Edit Custom Offer Group
      </Typography>
      <TextField
        label="Label"
        name="label"
        onChange={onChange}
        value={values.label}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Key"
        name="key"
        onChange={onChange}
        value={values.key}
        fullWidth
        margin="normal"
      />
      <GroupFieldSelector
        label="Fields"
        initialValues={offerData.fields}
        ref={fieldRef}
      />
      <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }}>
        Conditions
      </Typography>
      {conditions.map((condition) => (
        <div key={condition.id} style={{ display: 'flex', gap: '16px', marginBottom: '8px' }}>
          <TextField
            select
            label="Condition Type"
            value={condition.type}
            onChange={(e) => handleConditionChange(condition.id, 'type', e.target.value)}
            fullWidth
          >
            {conditionTypes.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={
                  selectedTypes.includes(option.value) && condition.type !== option.value
                }
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Value"
            required
            value={condition.value}
            onChange={(e) => handleConditionChange(condition.id, 'value', e.target.value)}
            type={
              condition.type === 'minLength' || condition.type === 'maxLength'
                ? 'number'
                : 'text'
            }
            fullWidth
          />
          <IconButton onClick={() => handleDeleteCondition(condition.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button variant="outlined" onClick={handleAddCondition} sx={{ marginBottom: 2 }}>
        Add Condition
      </Button>
      <LoadingButton
        onClick={handleSubmit}
        variant="contained"
        loading={loading}
        sx={{ marginTop: 2 }}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default EditCustomOfferGroup;
