import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Card, Box, IconButton, Tooltip, Chip } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OfferTableToolbar from './Toolbar';
import useOffer from '../useOffer';
import { OfferContext } from '../context';
import TooltipIconButton from '../../../components/TooltipIconButton';
import DynamicTable from '../../../components/DynamicTable';

import { Link } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QuickFilter from '../../../components/QuickFilter';

function OfferTable() {
  const { getOffers, deleteOffer } = useOffer();
  const [trafficMap, setTrafficMap] = useState(null);
  const [offers, setOffers] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(undefined);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [quickFilter, setQuickFilter] = useState('');
  const [filters, setFilters] = useState({
    name: [],
    offerLink: [],
    trafficSourceId: [],
  });

  const {
    traffic,
    loading: contextLoading,
    domains,
  } = useContext(OfferContext);

  const onAddOfferDone = (newOffer) => {
    const offerArray = [newOffer].flat();
    setOffers((prevOffers) => [...prevOffers, ...offerArray]);
  };

  const onEditOfferDone = (newOffer) => {
    const newOffers = offers.map((item) => {
      if (newOffer._id === item._id) return newOffer;
      return item;
    });
    setOffers(newOffers);
  };

  const onDeleteOfferDone = (id) => {
    const newOffers = offers.filter((item) => !id.includes(item._id));
    setOffers(newOffers);
  };

  const onImportOfferDone = (newOffer) => {
    const offerArray = [newOffer].flat();
    setOffers((prevOffers) => [...prevOffers, ...offerArray]);
  };

  useEffect(() => {
    if (offers.length > 0 && trafficMap) {
      const res = offers.filter((item) => {
        return Object.entries(filters).every(([key, value]) => {
          if (!(key in item)) {
            return (item[key] = '');
          }
          if (key === 'trafficSourceId') {
            if (item[key] === '') {
              return value.includes(item[key]);
            }
            const trafficSourceName = trafficMap[item[key]]?.name || '';
            return value.includes(trafficSourceName);
          } else {
            return value.includes(item[key]);
          }
        });
      });

      const filteredWithQuickFilter = res.filter((item) => {
        const lowercasedFilter = quickFilter.toLowerCase();

        const nameMatches = item.name.toLowerCase().includes(lowercasedFilter);
        const offerLinkMatches = item.offerLink
          .toLowerCase()
          .includes(lowercasedFilter);
        const trafficSourceName = trafficMap[item.trafficSourceId]?.name || '';
        const trafficSourceMatches = trafficSourceName
          .toLowerCase()
          .includes(lowercasedFilter);

        let redirectLink = '';
        if (item.redirectDomainId) {
          const domainData = domainMap[item.redirectDomainId];
          if (domainData && domainData.domainLink) {
            try {
              const domainObject = new URL(domainData.domainLink);
              redirectLink = `${domainObject.origin}/r/${item.uniqueId}`;
            } catch (error) {
              console.error('Invalid URL for redirect link:', error);
            }
          }
        }
        const redirectLinkMatches = redirectLink
          .toLowerCase()
          .includes(lowercasedFilter);

        return (
          nameMatches ||
          offerLinkMatches ||
          trafficSourceMatches ||
          redirectLinkMatches
        );
      });

      const sortedArray = filteredWithQuickFilter.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setFilteredOffers(sortedArray);
    }
  }, [offers, trafficMap, filters, quickFilter]);

  // console.log('offers', offers, filters);
  // console.log('offers: ', offers);
  // console.log('filters: ', filters);

  const renderCell = (type, data) => {
    switch (type) {
      case 'name':
        return data[type];
      case 'offerLink':
        return data[type];
      case 'redirectDomainId':
        const redirectDomainId = data.redirectDomainId || '';

        const domainData = domainMap[redirectDomainId];

        try {
          const domainObject = new URL(domainData.domainLink);
          return domainObject.origin + `/r/${data.uniqueId}`;
        } catch (error) {
          return <Chip color="error" label="Domain invalid" />;
        }
      case 'entryDomainId':
        const entryDomainId = data.entryDomainId || '';
        const entryDomainData = domainMap[entryDomainId];
        if (entryDomainData && entryDomainData.domainLink) {
          return entryDomainData.domainLink + `/offer/${data.uniqueId}`;
        } else {
          return <Chip color="error" label="No entry domain" />;
        }
      case 'trafficSourceId':
        const trafficSourceId = data.trafficSourceId || '';

        if (!trafficSourceId)
          return <Chip label="No traffic source" color="warning" />;

        const label = trafficMap[trafficSourceId]?.name;

        if (!label) return <Chip label="No traffic source" color="warning" />;

        return (
          <Link
            to={{
              pathname: `/traffic/${trafficSourceId}`,
              state: { trafficSourceId },
            }}
          >
            <Chip label={label} color="info" sx={{ cursor: 'pointer' }} />
          </Link>
        );
      case '_id':
        const openLink = `/r/${data.uniqueId}`;

        const redirectDomainId2 = data.redirectDomainId || '';
        const domainData2 = domainMap[redirectDomainId2];

        let clipboardText = ``;

        try {
          const domainObject = new URL(domainData2.domainLink);
          clipboardText = domainObject.origin + `/r/${data.uniqueId}`;
        } catch (error) {}
        return (
          <>
            <Box>
              <TooltipIconButton
                icon={<ContentCopyIcon />}
                title="Copy redirect link"
                onClick={() => copyToClipboard(clipboardText)}
                size="small"
                disabled={!clipboardText}
              />
              <Tooltip title="Open redirect link">
                <a
                  href={clipboardText}
                  target=" _blank"
                  rel="noreferrer noopenner"
                >
                  <IconButton>
                    <OpenInNewIcon />
                  </IconButton>
                </a>
              </Tooltip>
            </Box>
          </>
        );

      default:
        break;
    }
  };

  useEffect(() => {
    if (offers.length > 0 && trafficMap) {
      tableCols.map((item) => {
        if (item['filter']) {
          const res = Array.from(
            new Set(
              offers
                .filter(
                  (c) => c[item.field] !== null && c[item.field] !== undefined
                )
                .map((e) => {
                  if (item.renderFilter) {
                    return item.renderFilter(e[item.field]);
                  } else {
                    return e[item.field];
                  }
                })
            )
          );
          setFilters((prevFilters) => ({
            ...prevFilters,
            [item.field]: res,
          }));
        }
      });
    }
  }, [offers, trafficMap]);

  const init = () => {
    setLoading(true);
    const apiCall = getOffers();

    (async function () {
      try {
        const res = await apiCall.call();
        setOffers(res);
      } catch (e) {
      } finally {
      }
    })();

    setLoading(false);
    return apiCall.abort;
  };

  // This ensures that only one time that init function is called upon the page loads
  useEffect(() => {
    return init();
  }, []);

  useEffect(() => {
    const res = traffic.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});
    setTrafficMap(res);
  }, [traffic]);

  const domainMap = useMemo(() => {
    return domains.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});
  }, [domains]);

  const getTrafficSourceId = (val) => {
    for (const trafficSourceId in trafficMap) {
      if (trafficMap.hasOwnProperty(trafficSourceId)) {
        const trafficSource = trafficMap[trafficSourceId];
        if (trafficSource.name === val) {
          return trafficSourceId;
        }
      }
    }
    return '';
  };
  const getRedirectDomainId = (val) => {
    let trimmedVal;

    if (val.includes('/r/')) {
      trimmedVal = val.split('/r/')[0];
    } else {
      trimmedVal = val;
    }

    for (const domainId in domainMap) {
      if (domainMap.hasOwnProperty(domainId)) {
        const domain = domainMap[domainId];
        if (domain.domainLink === trimmedVal) {
          return domainId;
        }
      }
    }

    return '';
  };

  const getEntryDomainId = (val) => {
    for (const entryId in domainMap) {
      if (domainMap.hasOwnProperty(entryId)) {
        const entry = domainMap[entryId];
        if (entry.domainLink === val) {
          return entryId;
        }
      }
    }

    return '';
  };

  useEffect(() => {
    if (selectedRows.length > 1) {
      setSelectedRowData(undefined);
    } else {
      setSelectedRowData(offers.find((item) => item._id === selectedRows[0]));
    }
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [offers]);

  const generateTableCols2 = (trafficMap, domainMap, isLoading) => {
    const tableCols = [
      {
        name: 'Name',
        field: 'name',
        flex: 1,
        filter: true,
      },
      {
        name: 'Original offer link',
        field: 'offerLink',
        flex: 2,
        filter: true,
      },
      {
        name: 'Redirect link',
        field: 'redirectDomainId',
        flex: 3,
        filter: false,
      },
      {
        name: 'Entry domain',
        field: 'entryDomainId',
        flex: 4,
        filter: false,
      },
      {
        name: 'Traffic source',
        field: 'trafficSourceId',
        flex: 1,
        filter: true,
        renderFilter: (data) => {
          if (!data) {
            return '';
          }
          const trafficSourceId = data;
          const label = trafficMap[trafficSourceId]?.name || '';

          return label;
        },
      },
      {
        name: 'Actions',
        field: '_id',
        flex: 1,
        filter: false,
      },
    ];

    return tableCols;
  };

  const tableCols = generateTableCols2(trafficMap, domainMap, contextLoading);

  return (
    <Card>
      <OfferTableToolbar
        onAddOfferDone={onAddOfferDone}
        onEditOfferDone={onEditOfferDone}
        selectedRow={selectedRowData}
        onImportOfferDone={onImportOfferDone}
        onDeleteOfferDone={onDeleteOfferDone}
        rowToDelete={selectedRows}
        currentData={offers}
        getTrafficSourceId={getTrafficSourceId}
        getRedirectDomainId={getRedirectDomainId}
        getEntryDomainId={getEntryDomainId}
      />
      <QuickFilter quickFilter={quickFilter} setQuickFilter={setQuickFilter} />
      <>
        <DynamicTable
          columns={tableCols}
          originalData={offers}
          filteredData={filteredOffers}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          renderCell={renderCell}
          filters={filters}
          setFilters={setFilters}
        />
      </>
    </Card>
  );
}

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export default OfferTable;
