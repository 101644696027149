import { Box } from '@mui/material';
import PubslihersTable from './table'

function PublishersPage() {
  return (
    <Box mt={4}>
      <PubslihersTable />
    </Box>
  );
}

export default PublishersPage;
