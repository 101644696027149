import useApi from '../../hooks/useApi';
const API_URL = '/api/clicks';

export default function useClicks() {
  const { api: apiClient, createApiCall } = useApi();

  const getClicks = createApiCall(
    async (
      { signal },
      startDate,
      endDate,
      page,
      pageSize,
      sortColumn,
      sortDirection
    ) => {
      const { data: clicks } = await apiClient.get(API_URL, {
        signal,
        params: {
          startDate,
          endDate,
          page,
          pageSize,
          sortColumn,
          sortDirection,
        },
      });

      return clicks;
    }
  );

  return { getClicks };
}
