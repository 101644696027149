import { createContext, useEffect, useState } from 'react';
import useTraffic from '../traffic/useTraffic';
import useDomain from '../domains/useDomain';
import useOffer from './useOffer';
import useMerchants from './useMerchants';

export const OfferContext = createContext({});

export default function OfferProvider({ children }) {
  const { getTraffic, getAllTraffic } = useTraffic();
  const { getDomains } = useDomain();
  const { getOffers } = useOffer();
  const { getActiveMerchants } = useMerchants();

  const [traffic, setTraffic] = useState([]);
  const [domains, setDomains] = useState([]);
  const [offers, setOffers] = useState([]);

  const [loading, setLoading] = useState(false);

  const init = () => {
    setLoading(true);

    const trafficCall = getTraffic();
    const domainCall = getDomains();
    const offersCall = getOffers();
    const merchantsCall = getActiveMerchants();

    (async function () {
      try {
        const [
          loadedTraffic,
          loadedDomains,
          loadedOffers,
        ] = await Promise.all([
          trafficCall.call(),
          domainCall.call(),
          offersCall.call(),
        ]);


        setDomains(loadedDomains);
        setTraffic(loadedTraffic);
        setOffers(loadedOffers);

      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      trafficCall.abort();
      domainCall.abort();
      offersCall.abort();
      merchantsCall.abort();
    };
  };

  useEffect(() => {
    return init();
  }, []);

  return (
    <OfferContext.Provider
      value={{
        traffic,
        loading,
        domains,
        offers,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
}
