import React from 'react';
// import SimpleToolbar from '../../../components/SimpleToolbar';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SimpleToolbar from '../../components/SimpleToolbar';
import AddPublisher from './addPublisher';
import usePublisher from './usePublisher';
import EditPublisher from './editPublisher';

type Props = {
  selectedRowData: any;
  onAddPublisherDone?: any;
  onEditPublisherDone?: any;
};

function PublishersToolbar({
  selectedRowData,
  onAddPublisherDone,
  onEditPublisherDone,
}: Props) {
  const { addPublisher, editPublisher } = usePublisher();

  const onAddPublisher = async (newPublisher: any) => {
    try {
      const publisher = await addPublisher().call(newPublisher);
      onAddPublisherDone(publisher);
    } catch (error) {
      console.log({ error });
    }
  };

  const onEditPublisher = async (newDomain: any) => {
    const publisher = await editPublisher().call(selectedRowData._id, {
      ...newDomain,
    });

    onEditPublisherDone(publisher);
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: any) => {
        return (
          <AddPublisher
            open={open}
            handleClose={handleClose}
            onSubmit={onAddPublisher}
          />
        );
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedRowData),
      icon: <EditIcon />,
      render: (open: boolean, handleClose: any) => {
        return (
          <EditPublisher
            open={open}
            handleClose={handleClose}
            onSubmit={onEditPublisher}
            initialData={selectedRowData}
          />
        );
      },
    },
  ];

  return <SimpleToolbar modalButtons={modalButtons} />;
}

export default PublishersToolbar;
