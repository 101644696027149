import useApi from '../../hooks/useApi';

const API_URL = '/api/publisher';

type Domain = {
  publisherName: string;
  publisherId: string;
  publisherDomain: [string];
};

function usePublisher() {
  const { api: apiClient, createApiCall } = useApi();

  const getPublisher = createApiCall(async ({ signal }: any) => {
    const {
      data: { publisher },
    } = await apiClient.get(API_URL, { signal });

    return publisher;
  });

  const addPublisher = createApiCall(
    async ({ signal }: any, newDomain: Domain) => {
      const {
        data: { publisher },
      } = await apiClient.post(API_URL, newDomain, { signal });

      return publisher;
    }
  );

  const editPublisher = createApiCall(
    async ({ signal }: any, domainId: string, newDomain: Domain) => {
      const {
        data: { publisher },
      } = await apiClient.put(`${API_URL}/${domainId}`, newDomain, { signal });

      return publisher;
    }
  );

  const deletePublisher = createApiCall(
    async ({ signal }: any, domainId: string) => {
      await apiClient.delete(`${API_URL}/${domainId}`, { signal });
    }
  );

  return { getPublisher, addPublisher, editPublisher, deletePublisher };
}

export default usePublisher;
