import { Box, Chip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
// import ConfirmModal from '../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import PublishersToolbar from './toolbar';
import ConfirmModal from '../../components/ConfirmModal';
import usePublisher from './usePublisher';

type Publisher = {
  publishName: string;
  publishId: string;
  domain: [string];
  _id: string;
  createdAt: string;
  updateAt: string;
};

function PublishersTable() {
  const [publisher, setPublisher] = useState<Publisher[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const { getPublisher, deletePublisher } = usePublisher();

  const init = () => {
    setLoading(true);

    (async function () {
      try {
        setLoading(true);
        const publisher = await getPublisher().call();

        setPublisher(publisher);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  };

  const onAddPublisherDone = (newPublisher: Publisher) => {
    const newPublishers = publisher.slice();
    newPublishers.push(newPublisher);

    setPublisher(newPublishers);
  };

  const onEditPublisherDone = (newPublisher: Publisher) => {
    const newPublishers = publisher.map((item) => {
      if (newPublisher._id === item._id) return newPublisher;
      return item;
    });

    setPublisher(newPublishers);
  };

  const onDelete = async (id: string) => {
    try {
      setLoading(true);
      await deletePublisher().call(id);
      const newPublisher = publisher.filter(
        (publisher) => publisher._id !== id
      );
      setPublisher(newPublisher);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableCols: GridColDef[] = [
    {
      headerName: 'Publisher Name',
      field: 'publisherName',
      flex: 1,
    },
    {
      headerName: 'Publisher ID',
      field: 'publisherId',
      flex: 2,
    },
    {
      headerName: 'Publisher Domain',
      field: 'publisherDomain',
      flex: 4,
      renderCell: (params) => {
        const domain = params?.row?.publisherDomain || [];
        return (
          <div
            style={{
              width: '100%',
              whiteSpace: 'normal', // Allows text to wrap
              overflowWrap: 'break-word', // Wrap long words
              lineHeight: 1.2, // Adjust line height if necessary
            }}
          >
            {domain.map((data: any) => (
              <Chip
                key={data._id}
                label={data.domainName}
                style={{
                  margin: 3,
                  maxWidth: '100%', // Ensures the Chip doesn't exceed the cell width
                  whiteSpace: 'normal', // Allow text wrap inside the Chip
                  overflowWrap: 'break-word', // Wrap long domain names
                }}
              />
            ))}
          </div>
        );
      },
    },
    {
      headerName: 'Actions',
      field: '_id',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <ConfirmModal
              onConfirm={() => {
                onDelete(params.row._id);
              }}
              title="Delete"
              icon={<DeleteIcon />}
            >
              Do you want to delete this publisher?
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    return init();
  }, []);

  const selectedRowData = useMemo(() => {
    if (!selectedRow[0]) return null;

    return publisher.find((item) => item._id === selectedRow[0]);
  }, [publisher, selectedRow]);

  return (
    <DataGrid
      autoHeight
      rows={publisher}
      columns={tableCols}
      getRowId={(r) => r._id}
      onRowSelectionModelChange={setSelectedRow as any}
      loading={loading}
      slots={{
        toolbar: () => {
          return (
            <PublishersToolbar
              selectedRowData={selectedRowData}
              onAddPublisherDone={onAddPublisherDone}
              onEditPublisherDone={onEditPublisherDone}
            />
          );
        },
      }}
      sx={{
        '& .MuiDataGrid-cell': {
          whiteSpace: 'normal', // Allow cell text to wrap
          lineHeight: 1.5, // Adjust line spacing for clarity
          overflowWrap: 'break-word', // Break long words
        },
      }}
    />
  );
}

export default PublishersTable;
