import { Autocomplete, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SimpleModal from '../../components/SimpleModal';
import LoadingButton from '../../components/LoadingButton';
import useDomain from '../domains/useDomain';

type Props = {
  open: boolean;
  handleClose: () => void;
  onSubmit: (newValues: Pubsliher) => Promise<any>;
};

type Pubsliher = {
  publisherName: string;
  publisherId: string;
  publisherDomain: string[];
};

function AddPublisher({ open, handleClose, onSubmit }: Props) {
  const [values, setValues] = useState({
    publisherName: '',
    publisherId: '',
    publisherDomain: [],
  });
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [pnError, setPNError] = useState<boolean>(false);
  const [pIdError, setPIdError] = useState<boolean>(false);

  const [pnErrorMessage, setPNErrorMessage] = useState<string>('');
  const [pIdErrorMessage, setPIdErrorMessage] = useState<string>('');

  const { getDomainsIsEntry } = useDomain();

  const init = async () => {
    const domains = await getDomainsIsEntry().call();
    setDomains(domains);
  };

  const onChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'publisherName') {
      if (e.target.value !== '') {
        setPNError(false);
        setPNErrorMessage('');
      }
    }

    if (name === 'publisherId') {
      if (e.target.value !== '') {
        setPIdError(false);
        setPIdErrorMessage('');
      }
    }
    setValues((old) => ({ ...old, [name]: value }));
  };

  const transformId = (domains: any[]) => {
    const transformedDomains = domains.map(({ _id, ...rest }) => ({
      domainId: _id,
      ...rest,
    }));

    return transformedDomains
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      values.publisherDomain = selectedOptions;
      if (values.publisherName.trim() === '') {
        setPNError(true);
        setPNErrorMessage('Publisher name is required');
      }
      if (values.publisherId.trim() === '') {
        setPIdError(true);
        setPIdErrorMessage('Publisher ID is required');
      }
      if (
        values.publisherName.trim() !== '' &&
        values.publisherId.trim() !== ''
      )
        await onSubmit(values);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add Publisher</Typography>
      <TextField
        onChange={onChange}
        name="publisherName"
        label="Publisher Name"
        error={pnError}
        helperText={pnErrorMessage}
      />
      <TextField
        onChange={onChange}
        name="publisherId"
        label="Publisher ID"
        error={pIdError}
        helperText={pIdErrorMessage}
      />
      <Autocomplete
        multiple
        options={transformId(domains)}
        getOptionLabel={(option) => option.domainName}
        isOptionEqualToValue={(option, value) => option.domainId === value.domainId}
        value={selectedOptions}
        onChange={(event, newValue) => {
          setSelectedOptions(
            newValue.map((option) => ({
              domainId: option.domainId,
              domainName: option.domainName,
            }))
          );
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Select Domains" />
        )}
      />

      <LoadingButton
        loading={loading}
        onClick={handleSubmit}
        variant="contained"
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
}

export default AddPublisher;
