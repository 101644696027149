import { Typography, TextField, Button, Autocomplete } from '@mui/material';
import { useContext, useState } from 'react';
import { OfferContext } from '../context';
import SimpleModal from '../../../components/SimpleModal';

export default function EditOffer({ open, initialData, handleClose, onSubmit }) {
  const [values, setValues] = useState(initialData);
  const offerContext = useContext(OfferContext);

  const { traffic, domains } = offerContext;

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleTrafficSourceChange = (event, newValue) => {
    setValues({
      ...values,
      trafficSourceId: newValue ? newValue._id : '',
    });
  };

  const handleRedirectDomainChange = (event, newValue) => {
    setValues({
      ...values,
      redirectDomainId: newValue ? newValue._id : '',
    });
  };

  const handleEntryDomainChange = (event, newValue) => {
    setValues({
      ...values,
      entryDomainId: newValue ? newValue._id : '',
    });
  };

  const activeDomains = domains.filter((item) => item.active);
  const entryDomains = domains.filter((item) => item.active && item.isEntry === true);

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6" mb={2}>
        Edit offer
      </Typography>

      <TextField
        name="name"
        onChange={onChange}
        label="Name"
        value={values.name}
        fullWidth
      />
      <TextField
        onChange={onChange}
        fullWidth
        label="Offer link"
        name="offerLink"
        value={values.offerLink}
      />        
      <TextField
        onChange={onChange}
        fullWidth
        label="Fallback Url"
        value={values.fallBackUrl}
        name="fallBackUrl"
      />

      <Autocomplete
        options={traffic}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        value={traffic.find((item) => item._id === values.trafficSourceId) || null}
        onChange={handleTrafficSourceChange}
        renderInput={(params) => (
          <TextField {...params} label="Traffic Source" fullWidth />
        )}
      />
      <Autocomplete
        options={activeDomains}
        getOptionLabel={(option) => option.domainName}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        value={activeDomains.find(
          (item) => item._id === values.redirectDomainId
        ) || null}
        onChange={handleRedirectDomainChange}
        renderInput={(params) => (
          <TextField {...params} label="Redirect Domain" fullWidth />
        )}
      />
      <Autocomplete
        options={entryDomains}
        getOptionLabel={(option) => option.domainName}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        value={entryDomains.find(
          (item) => item._id === values.entryDomainId
        ) || null}
        onChange={handleEntryDomainChange}
        renderInput={(params) => (
          <TextField {...params} label="Entry Domain" fullWidth />
        )}
      />

      <Button variant="contained" onClick={() => onSubmit(values)}>
        Edit
      </Button>
    </SimpleModal>
  );
}
