import { MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import LoadingButton from '../../../components/LoadingButton';
import SimpleModal from '../../../components/SimpleModal';

type Props = {
  open: boolean;
  handleClose: () => void;
  onSubmit: (newValues: Domain) => Promise<any>;
  initialData: Domain;
};

type Domain = {
  domainName: string;
  domainLink: string;
  active: boolean;
  isEntry: boolean;
};

function EditDomain({ open, handleClose, onSubmit, initialData }: Props) {
  const [values, setValues] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setValues(old => ({ ...old, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit(values);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Edit domain</Typography>
      <TextField
        onChange={onChange}
        name="domainName"
        label="Domain Name"
        value={values.domainName}
      />
      <TextField
        onChange={onChange}
        name="domainLink"
        label="Domain Link"
        value={values.domainLink}
      />
      <TextField
        onChange={onChange}
        name="active"
        label="Active state"
        select
        InputLabelProps={{ shrink: true }}
        value={+values.active}
      >
        <MenuItem value={1}>Active</MenuItem>
        <MenuItem value={0}>Inactive</MenuItem>
      </TextField>
      <TextField
        onChange={onChange}
        name="isEntry"
        label="Is Entry"
        select
        InputLabelProps={{ shrink: true }}
        value={values?.isEntry ? 1 : 0}
      >
        <MenuItem value={1}>Yes</MenuItem>
        <MenuItem value={0}>No</MenuItem>
      </TextField>
      <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">
        Submit
      </LoadingButton>
    </SimpleModal>
  );
}

export default EditDomain;
